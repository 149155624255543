import React, { useState } from "react"
import { Transition } from "@headlessui/react"
import { getImage } from "gatsby-plugin-image"
import { graphql, Link, useStaticQuery } from "gatsby"

import { ChevronLeftIcon } from "@heroicons/react/outline"
import {
  Breadcrumb,
  Footer,
  Hero,
  Navigation,
} from "../../components/Marketing"
import { Seo } from "../../components/Seo/Seo"

export default function Quizz() {
  const breadcrumbs = [{ label: "Quizz", url: "/quizz/" }]
  const query = useStaticQuery(graphql`
    query GetQuizzImage {
      file(relativePath: { eq: "illustrations/quizz.jpeg" }) {
        id
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            layout: CONSTRAINED
            width: 1200
            quality: 80
          )
        }
      }
    }
  `)

  const illustration = getImage(query.file.childImageSharp)

  return (
    <>
      <Seo
        title="Les quizz du Temple du Haïku !"
        description="Mettez votre connaissance de la poésie à l'épreuve dans nos quizz."
        breadcrumbs={breadcrumbs}
        image={illustration.images.fallback.src}
      />
      <Navigation />
      <Hero
        theme={{ as: "div", value: <Breadcrumb items={breadcrumbs} /> }}
        title={{
          as: "h1",
          value: <>Quizz sur les règles de comptages de syllabes !</>,
        }}
        illustration={illustration}
        description={{
          value: (
            <>
              Bienvenue dans le grand quizz du Temple du Haïku !
              <br />
              <br />
              Venez tester vos connaissances en poésie et prouvez à tout le
              monde que vous êtes passionés.
              <Link
                to="/quizz/syllabes/"
                className={`block w-full mt-8 cursor-pointer p-4 text-xl font-medium text-center text-white duration-300 ease-in-out bg-green-500 rounded-lg shadow-lg  hover:bg-green-600`}
              >
                Commencer
              </Link>
            </>
          ),
        }}
      />

      <Footer />
    </>
  )
}
